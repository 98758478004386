import { Checkbox, Text } from '@iheartradio/web.companion';

type EmailOptInProps = {
  disabled?: boolean;
};

export const EmailOptIn = (props: EmailOptInProps) => {
  return (
    <Checkbox
      data-test="email-opt-out"
      defaultChecked
      disabled={props.disabled}
      id="email-opt-out"
      name="emailOptOut"
      value="1"
    >
      <Text
        as="p"
        color={props.disabled ? '$gray-400' : 'inherit'}
        kind={{
          '@medium': 'caption-2',
          '@initial': 'caption-4',
        }}
      >
        Get emails for the latest news and updates from iHeart.
      </Text>
    </Checkbox>
  );
};
